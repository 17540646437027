html,
body {
  font-size: 16px;
  background-color: #282c34;
  color: white;
}

.ui.modal {
  color: rgba(0, 0, 0, 0.87);
}

.ui.grid {
  margin: 0 !important;
}

.App {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.playerName {
  position: absolute;
  top: 5px;
  left: 5px;
  color: white;
  font-weight: bold;
}

.menu {
  position: absolute;
  top: 5px;
  right: 5px;
}

.lastTrick {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  z-index: 1;
}

.lastTrick-toggle {
  padding: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.lastTrick-toggle.clickable {
  cursor: pointer;
}

.lastTrick .message {
  position: relative;
  overflow: hidden;
}

.lastTrick-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: white;
  animation: lastTrickProgressBar 10s linear;
}

@keyframes lastTrickProgressBar {
  0% { width: 0; }
  100% { width: 100%; }
}

.lastTrick-stack {
  font-size: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  overflow: hidden;
  margin-top: 1em;
}

.lastTrick-stack-svg {
  position: relative;
  overflow: hidden;
  height: 34px;
  width: 30px;
}

.lastTrick-stack-svg-userChar {
  position: absolute;
  top: -3px;
  right: 4px;
  color: black;
}

.lastTrick-counter {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.table {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-align-center {
  text-align: center;
}

.flex-1 {
  flex: 1;
}

.flex-column-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.controls {
  flex-shrink: 0;
  margin: 1.5em 2em;
  width: calc(100% - 4em);
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 42px;
}

.lastTrick .ui.message,
.controls > .ui.message {
  margin: 0;
}

.myCards {
  font-size: 0.9vw;
}

.myCards.povertyReceived {
  font-size: 0.7vw;
}

.sortableCards {
  display: flex;
}

.sortableCard {
  margin: 2px;
  position: relative;
}

.sortableCard.isSelected {
  transform: scale(1.1);
}

.sortableCard-drag {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.playersTable {
  position: relative;
}

.playersTable.withInactive {
  margin-top: 2em !important;
}

.playerTile {
  text-align: center;
  width: calc(100px + 4rem); /* (10px font-size * 1em svg width) + (2x 1rem padding) */
}

.playerTile.inactive {
  position: absolute;
  top: -50px;
  opacity: 0.5;
}

.playerTile-name {
  border: 1px solid transparent;
  padding: 3px;
  border-radius: 5px;
}

.playerTile-name.isStarting {
  border-color: white;
}

.playerTile.playing {
  min-height: 180px;
}

.playerTile-health {
  margin-top: 1em;
}

.playerTile-card {
  font-size: 10px;
  margin-top: 1em;
}

.playerTile-points {
  font-size: 24px;
  font-weight: bold;
  margin: 0.75em 0 1em 0;
}

.playerTile-trick {
  display: flex;
  font-size: 10px;
  height: 24px;
  overflow: hidden;
  border-radius: 4px;
}

.playerTile-trick.isDoppelKopf {
  transform: scale(1.1);
}


.playerTile-trick + .playerTile-trick {
  margin-top: 3px;
}

.playerTile-trick-svg {
  position: relative;
  width: 32px;
}

.playerTile-trick-svg.cardHighLight::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(33, 133, 208, .2);
}


.playerTile-trick-svg-userChar {
  position: absolute;
  top: -3px;
  right: 4px;
  color: black;
}

.sittingOrder {
  position: relative;
}

.sortablePlayer {
  margin: .5em 0;
}

.ui.buttons .or:before {
  content: 'II';
}

.controls .grid {
  width: 100%;
}
